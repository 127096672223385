import lagunag1 from "./resources/images/laguna_galeria/laguna1.jpg";
import lagunag2 from "./resources/images/laguna_galeria/laguna2.jpg";
import lagunag3 from "./resources/images/laguna_galeria/laguna3.jpg";
import lagunag4 from "./resources/images/laguna_galeria/laguna4.jpg";
import lagunag5 from "./resources/images/laguna_galeria/laguna5.jpg";
import lagunag6 from "./resources/images/laguna_galeria/laguna6.jpg";
import lagunag7 from "./resources/images/laguna_galeria/laguna7.jpg";
import lagunag8 from "./resources/images/laguna_galeria/laguna8.jpg";
import lagunag9 from "./resources/images/laguna_galeria/laguna9.jpg";
import lagunag10 from "./resources/images/laguna_galeria/laguna10.jpg";
import sasanka1 from "./resources/images/sasanka/sasanka1.jpg";
import sasanka2 from "./resources/images/sasanka/sasanka2.jpg";
import sasanka3 from "./resources/images/sasanka/sasanka3.jpg";
import sasanka4 from "./resources/images/sasanka/sasanka4.jpg";
import sasanka5 from "./resources/images/sasanka/sasanka5.jpg";
import sasanka6 from "./resources/images/sasanka/sasanka6.jpg";
import sasanka8 from "./resources/images/sasanka/sasanka8.jpg";
import sasanka9 from "./resources/images/sasanka/sasanka9.jpg";
import sasanka_main from "./resources/images/sasanka_main.jpg";
import laguna_main from "./resources/images/laguna_main.jpg";
export const boats = [
  {
    name: 'Laguna 730 "Charlie"',
    description:
      "Szybki, zwrotny i łatwy w prowadzeniu jacht dla maksymalnie 8 osób o bardzo interesujących kształtach kadłuba i funkcjonalnie zagospodarowanej przestrzeni wnętrza. Laguna to rodzinna jednostka turystyczna, w której położono nacisk na bezpieczeństwo, wygodę oraz łatwość obsługi, jednak dzięki zwiększonej powierzchni ożaglowania jacht jest w stanie zadowolić nawet najbardziej wymagających żeglarzy. Łódź jest bardzo dobrze wyposażona, a w 2021 roku przeszła generalny lifting.",
    technicalData: [
      "Długość 7,3m",
      "Szerokość 2,74m",
      "Pow. Żagli 30m<sup>2</sup>",
      "Zanurzenie min/max 0,32/ 1,50",
      "Wysokość stania w kabinie 1,85 m",
      "Ilość miejsc do spania 8 (komfortowo 6)",
      "Typ steru: płetwa na pawęży",
      "Typ miecza: uchylny",
      "Patent do stawiania masztu: bramka",
      "Silnik zaburtowy Mercury 5km",
    ],
    equipment: [
      "Grot z możliwością refowania (2 refbanty)",
      "Lazy Jack",
      "Rolfok na sztywnym sztagu",
      "Relingi",
      "Tent",
      "Farelka grzewcza",
      "6 odbijaczy",
      "Koło ratunkowe",
      "Saperka i siekierka",
      "Silnik z linią paliwową",
      "Lodówka sprężarkowa zabudowana w bakiście",
      "Rufowa drabinka kąpielowa",
      "2 kotwice z linami",
      "Cumy",
      "Korba do kabestanu",
      "Wiadro, miska, mop, szczotka do szorowania pokładu",
      "Odkurzacz bezprzewodowy",
      "Stolik kokpitowy",
      "Moskitiera",
      "Przedłużacz + przejściówka",
      "2 palniki gazowe",
      "Kambuz wyposażony w naczynia kuchenne, zastawę stołową, sztućce",
      "Butla gazowa 3kg, 5 litrów paliwa, zbiorniki na wodę 2x20 litrów, dwa naładowane akumulatory (100 ah)",
      "Instalacja wodna w kambuzie i WC",
      "Gniazdo USB",
      "Gniazdo 230V",
      "Komplet poduszek",
      "Gaśnica, 8 kamizelek",
      "Szufelka ze zmiotką",
      "Radio CD z kablem AUX",
      " Jacht oraz silnik są ubezpieczone w zakresie: OC, JACHT CASCO, NW I rzeczy osobistych załogi",
    ],
    mainImage: laguna_main,
    images: [
      lagunag1,
      lagunag2,
      lagunag6,
      lagunag3,
      lagunag5,
      lagunag7,
      lagunag8,
      lagunag9,
      lagunag10,
      lagunag4,
    ],
  },
  {
    name: "Sasanka 660",
    description:
      "Sasanka 660 to jednostka turystyczna, w której położono nacisk na bezpieczeństwo, wygodę i łatwość obsługi. Dzięki wszystkim fałom sprowadzonym do kokpitu, jednoosobowa obsługa łódki nie stanowi żadnego problemu. Na jachcie Może nocować nawet pięcioosobowa załoga. Dzięki solidnej konstrukcji i przemyślanym rozwiązaniom jacht umożliwia bezpieczną żeglugę i proste manewrowanie. Dodatkowo zawiera roler grota, który znacznie ułatwia proces stawiania i refowania żagla. Dzięki 10-konnemu silnikowi i doprowadzonej oddzielnej manetce, manewry portowe są łatwe i wygodne.\n\nJacht w sezonie 2023 przejdzie drobny remont i lifting, zdjęcia na stronię są jeszcze sprzed remontu. W sezonie 2023 jacht zostanie zaopatrzony w nowe wykładziny podłogowe oraz nową podsufitkę. Uszyte zostaną nowe pokrowce na materace. Burty oraz pokład jachtu zostaną całkowicie wypolerowane.",
    technicalData: [
      "Długość 6,60 m",
      "Szerokość 2,50 m",
      "Pow. Żagli 20m2",
      "Zanurzenie min/max 0,32/ 1,20",
      "Wysokość stania w kabinie 1,60 m",
      "Ilość miejsc do spania: 4 (komfortowo 3)",
      "Typ steru: płetwa na pawęży",
      "Typ miecza: uchylny",
      "Patent do stawiania masztu: bramka",
      "Silnik zaburtowy Tohatsu 10KM z oddzielną manetką.",
    ],
    equipment: [
      "Grot na rolerze (możliwość dowolnego refowania żagla)",
      "rolfok na sztywnym sztagu",
      "bramka do kładzenia masztu",
      "relingi",
      "tent",
      "farelka grzewcza",
      "4 odbijacze",
      "koło ratunkowe",
      "saperka i siekierka,2 pagaje",
      "bosak",
      "przedłużka rumpla",
      "silnik z linią paliwową i oddzielną manetką",
      "oświetlenie LED",
      "rufowa drabinka kąpielowa",
      "2 kotwice z linami",
      "cumy",
      "korba do kabestanu",
      "wiadro",
      "miska",
      "mop",
      "szczotka do szorowania pokładu",
      "stolik kokpitowy",
      "moskitiera",
      "przedłużacz + przejściówka",
      "2 palniki gazowe",
      "kambuz wyposażony w naczynia kuchenne",
      "zastawę stołową",
      "sztućce",
      "butla gazowa 3kg",
      "10 litrów paliwa",
      "zbiorniki na wodę",
      "naładowany akumulator (80 ah)",
      "instalacja wodna w kambuzie",
      "kingstone z toaletą chemiczną",
      "gniazdo 12V",
      "prostownik do akumulatora",
      "gaśnica",
      "6 kamizelek",
      "szufelka ze zmiotką",
      "wszystkie fały sprowadzone do kokpitu",
      "kosz na odbijacze",
      "Jacht oraz silnik są ubezpieczone w zakresie: OC, JACHT CASCO, NNW",
    ],
    mainImage: sasanka_main,
    images: [
      sasanka8,
      sasanka9,
      sasanka1,
      sasanka2,
      sasanka6,
      sasanka3,
      sasanka4,
      sasanka5,
    ],
  },
];

export const nameToPath = (name) => "/" + name.split(" ")[0].toLowerCase();
