import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import AnchorIcon from "@mui/icons-material/Anchor";
import { useNavigate } from "react-router-dom";
import FacebookIcon from '@mui/icons-material/Facebook';
import "./styles/css/footer.css";
const Footer = () => {

    const navigate = useNavigate();
    return (
        <footer>
            <div className="footer-contacts">
                <div className="phone-number footer">
                    <PhoneIcon className="icon" /> <span>+48 696 967 840</span>
                </div>
                <div className="email footer">
                    <EmailIcon className="icon" />{" "}
                    <span>redjacht.czarter@onet.pl</span>
                </div>
                <div className="address footer">
                    <AnchorIcon className="icon" />{" "}
                    <span>Łabędzi Ostrów Ośrodek Wypoczynkowy na Mazurach</span>
                </div>
                <a className="facebook footer" href="https://www.facebook.com/Redjachtczarter">
                    <FacebookIcon className="icon" />{" "}
                    <span>Red Jacht Czarter</span>
                </a>
            </div>
        </footer>
    );
};
export default Footer;
