import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import AnchorIcon from "@mui/icons-material/Anchor";
import { Map } from "react-leaflet";
import { TileLayer } from "react-leaflet";
import { Marker } from "react-leaflet";
import { Popup } from "react-leaflet";
import { MapContainer } from "react-leaflet";
import "./styles/css/contact.css";
export default function Contact() {
    return (
        <div className="contact-page">
            <h1>Kontakt i Rezerwacja</h1>
            <div className="contact-info">
                <h2>Możesz do nas napisać lub zadzwonić!</h2>
                <p>
                    <PhoneIcon className="icon" />
                    <span>Kuba +48 696 967 840</span>
                </p>
                <p>
                    <EmailIcon className="icon" />
                    <span>redjacht.czarter@onet.pl</span>
                </p>
            </div>

            <div className="port">
                <h2>Port Macierzysty</h2>
                <p>Łabędzi Ostrów, Piękna Góra koło Giżycka.</p>
                <p>Jezioro Kisajno.</p>
                <div id="map">
                    <MapContainer
                        center={[54.03279870559491, 21.721338923267677]}
                        zoom={15}
                        scrollWheelZoom={false}
                    >
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker
                            position={[54.03279870559491, 21.721338923267677]}
                        >
                            <Popup>
                                Łabędzi Ostrów, Piękna Góra koło Giżycka.
                            </Popup>
                        </Marker>
                    </MapContainer>
                </div>
            </div>

            <div className="reservation">
                <h2>Procedura Rezerwacji</h2>
                <p>
                    <strong>Termin: </strong>Termin czarteru należy uzgodnić
                    telefonicznie lub poprzez e-mail.
                </p>
                <p>
                    <strong>Umowa: </strong>Pocztą lub mailem zostanie przesłana
                    umowa najmu, którą po podpisaniu, należy odesłać wraz z
                    kserokopią dowodu wpłaty zaliczki.
                </p>
                <p>
                    <strong>Zaliczka: </strong>Należy wpłacić zaliczkę w
                    wysokości 30% wartości czarteru. Po jej wpłynięciu i
                    odesłaniu umowy, potwierdzimy rezerwację. Resztę kwoty razem
                    z kaucją należy wpłacić najpóźniej w dniu rejsu gotówką lub
                    przelewem ekspresowym.
                </p>
            </div>

            <h2 className="wishes">RED JACHT CZARTER ŻYCZY UDANEGO REJSU!</h2>
        </div>
    );
}
