import "../styles/css/laguna.css";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import { SRLWrapper } from "simple-react-lightbox";

const Boat = ({ boat }) => {
    const [expanded, setExpanded] = useState("tech");
    const handleExpansion = (whatToExpand) => {
        setExpanded((prev) => (prev === whatToExpand ? "" : whatToExpand));
    };
    return (
        <div className="laguna-page">
            <h1>{boat.name}</h1>
            <div className="photo-specification-container">
                <div className="laguna-photo-container">
                    <img src={boat.mainImage} alt="lagunka" />
                </div>
                <div className="specifications">
                    <p className="description">
                        {boat.description}
                    </p>
                    <div
                        className={
                            "technics" +
                            (expanded === "tech" ? " expanded" : "")
                        }
                    >
                        <div
                            className="technical-data title"
                            onClick={() => handleExpansion("tech")}
                        >
                            <p>Dane techniczne</p>
                            <KeyboardArrowDownIcon className="icon" />
                        </div>
                        <ul>
                            {boat.technicalData.map(el => <li key ={el}>{el}</li>)}
                        </ul>
                    </div>
                    <div
                        className={
                            "equipment" + (expanded === "eq" ? " expanded" : "")
                        }
                    >
                        <div
                            className="technical-data title"
                            onClick={() => handleExpansion("eq")}
                        >
                            <p>Wyposażenie</p>
                            <KeyboardArrowDownIcon className="icon" />
                        </div>
                        <ul>
                            {boat.equipment.map(el => <li key ={el}>{el}</li>)}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="laguna-images">
                <h1>Galeria</h1>
                <div className="pictures">
                    <SRLWrapper>
                        {boat.images.map((el,i) => <img key={i} alt={"łódź" + i} src={el}></img>)}
                    </SRLWrapper>
                </div>
            </div>
        </div>
    );
};

export default Boat;
