import React, { useState } from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import "./styles/css/navbar.css";
import card from "./resources/images/1.png";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useWindowSize } from "./hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import { navigateTo } from "./utils/navigation";
import { boats, nameToPath } from "./boats";
const Navbar = () => {
    const [isOpen, open] = useState(false);
    const scrollY = useWindowSize();
    const navigate = useNavigate();
    return (
        <div className={"navbar-container" + (scrollY !== 0 ? " smaller" : "")}>
            <div className="second-navbar">
                <img
                    src={card}
                    onClick={() => navigateTo("/", navigate, open)}
                    alt="wizytówka"
                />

                {!isOpen ? (
                    <KeyboardArrowDownIcon
                        className="expander"
                        onClick={() => open((prev) => !prev)}
                    />
                ) : (
                    <KeyboardArrowUpIcon
                        className="expander"
                        onClick={() => open((prev) => !prev)}
                    />
                )}

                <div className={"tabs" + (isOpen ? " active" : "")}>
                    <div
                        onClick={() => navigateTo("/", navigate, open)}
                        className="tab"
                    >
                        <span>Strona Główna</span>
                    </div>
                    {boats.map(boat =><div key ={boat.name}
                        onClick={() => navigateTo(nameToPath(boat.name), navigate, open)}
                        className="tab"
                    >
                        <span>{boat.name}</span>
                    </div> )}
                    
                    <div
                        onClick={() => navigateTo("/deski-sup", navigate, open)}
                        className="tab"
                    >
                        <span>Wypożyczalnia desek SUP</span>
                    </div>
                    <div
                        onClick={() => navigateTo("/cennik", navigate, open)}
                        className="tab"
                    >
                        <span>Cennik</span>
                    </div>
                    <div
                        onClick={() => navigateTo("/kontakt", navigate, open)}
                        className="tab"
                    >
                        <span>Kontakt i rezerwacja</span>
                    </div>
                </div>
            </div>
            <div className="upper-navbar">
                <div className="phone-number">
                    <PhoneIcon className="icon" /> <span>+48 696 967 840</span>
                </div>
                <div className="email">
                    <EmailIcon className="icon" />{" "}
                    <span>redjacht.czarter@onet.pl</span>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
