import "./styles/css/sup.css";

import { SRLWrapper } from "simple-react-lightbox";
import sup1 from "./resources/images/supy/sup_na_glowna.jpg";
import sup2 from "./resources/images/supy/deska_kafelek.jpg";
import sup3 from "./resources/images/supy/sup3.jpg";
import sup4 from "./resources/images/supy/sup4.png";
const DeskiSup = () => {
    return (
        <div className="sup-page">
            <h1>Wypożyczalnia Desek Sup</h1>
            <div className="sup-photo-specification-container">
                <div className="sup-photo-container">
                    <SRLWrapper>
                        <img src={sup1} alt="" className="sup1" />

                        <img src={sup2} alt="" className="sup2" />
                        <img src={sup3} alt="" className="sup2" />
                        <img src={sup4} alt="" className="sup2" />
                    </SRLWrapper>
                </div>
                <div className="right-side">
                    <div className="sup-why-great-idea">
                        <h2>
                            Dlaczego pompowana deska SUP to dobry pomysł na
                            wakacje na mazurach?
                        </h2>

                        <ul>
                            <li>
                                Fantastyczna forma rekreacji nad wodą zyskująca
                                coraz większą popularność na świecie i w Polsce.
                            </li>
                            <li>
                                Spakujesz ją w plecak, więc możesz zabrać ją ze
                                sobą wszędzie, bez problemu zmieści się do
                                samochodu czy na rower.
                            </li>
                            <li>
                                Możesz nią dopłynąć w najpiękniejsze zakamarki
                                mazurskich jezior.
                            </li>
                            <li>
                                Popływasz na niej stojąc, siedząc czy klęcząc,
                                możesz się na niej położyć i opalać na środku
                                jeziora, a nawet uprawiać jogę.
                            </li>
                            <li>
                                Świetnie odnajdzie się również na żaglówce czy
                                motorówce, nie musisz nawet spuszczać z niej
                                powietrza wystarczy, że przywiążesz ją do
                                relingów łódki.
                            </li>
                        </ul>
                    </div>
                    <div className="sup-items-in-package">
                        {/* <div> */}
                        <h2>W zestawie dostaniesz:</h2>
                        <ol>
                            <li>Leash - linkę bezpieczeństwa</li>
                            <li>Statecznik</li>
                            <li>Regulowane wiosło</li>
                            <li>Pompkę</li>
                            <li>Plecak, w który zapakujesz deskę</li>
                            <li>Instrukcję obsługi</li>
                        </ol>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeskiSup;
