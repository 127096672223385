import React from "react";
import "./styles/css/pricesPage.css";
const PricesPage = () => (
  <div className="prices-page">
    <h1>Cennik</h1>
    <div className="prices-container">
      <h2>Ceny czarteru w sezonie 2023.</h2>
      <p>Ceny brutto w złotych za dobę czarterową.</p>
      <div className="table-container">
        <table className="price-table">
          <tbody>
            <tr>
              <td>Jacht</td>
              <td>Od 01.05 do 05.05</td>
              <td>Od 05.05 do 29.05</td>
              <td>Od 30.05 do 02.06</td>
              <td>Od 02.06 do 22.06</td>
              <td>Od 22.06 do 29.06</td>
              <td>Od 29.06 Do 17.08</td>
              <td>Od 17.08 do 24.08</td>
              <td>Od 24.08 Do 31.08</td>
              <td>Od 31.08 Do 14.09</td>
              <td>Po 14.09</td>
            </tr>
            <tr>
              <td>Laguna 730</td>
              <td>350</td>
              <td>260</td>
              <td>550</td>
              <td>250</td>
              <td>360</td>
              <td>380</td>
              <td>320</td>
              <td>300</td>
              <td>250</td>
              <td>220</td>
            </tr>
            <tr>
              <td>Sasanka 660</td>
              <td>260</td>
              <td>200</td>
              <td>400</td>
              <td>215</td>
              <td>250</td>
              <td>290</td>
              <td>230</td>
              <td>210</td>
              <td>190</td>
              <td>160</td>
            </tr>
            <tr>
              <td>Deska Sup</td>
              <td colSpan={10}>
                120 zł doba <br></br>
                300 zł - 3/5 dni <br></br>
                450zł - 7 dni
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="attention">
        Uwaga: Klientom czarterującym dowolny jacht z naszej oferty, przysługuje
        zniżka 50% na wypożyczenie deski SUP, pod warunkiem wynajmu jej na całą
        długość swojego czarteru.
      </p>
    </div>

    <div className="prices-info">
      <p>Armator zastrzega sobie prawo do zmian w cenniku.</p>
      <p>
        W okresie od 17 Czerwca do 2 Września jacht czarterujemy w modułach
        tygodniowych (sobota-sobota), w pozostałych na doby.
      </p>
    </div>
    <div className="info-container-container">
      <div className="discounts  info-container">
        <h2>Rabaty</h2>
        <ul>
          <li>10% dla stałych klientów</li>
          <li>
            5% dla studentów w sezonie głównym i 10 % dla studentów w Maju i
            Wrześniu
          </li>
          <li>5% czarter na 2 lub więcej tygodni</li>
        </ul>
        <p>Maksymalny łączny rabat nie może przekraczać 10%</p>
      </div>
      <div className="gratis info-container">
        <h2>Gratis</h2>
        <ul>
          <li>2 naładowane akumulatory żelowe</li>
          <li>Zatankowana woda</li>
          <li>Nabita butla gazowa</li>
          <li>5 litrów paliwa</li>
        </ul>
      </div>

      <div className="extra-money info-container">
        <h2>Dodatkowe Opłaty</h2>
        <ul>
          <li>Sternik na jachcie 200/250 zł + wyżywienie</li>
          <li>Pies na pokładzie 100 zł</li>
          <li>
            Za brudny i niesklarowany jacht po czarterze opłata wynosi od 100 do
            200 zł
          </li>
          <li>Za nieopróżnienie i nieumycie toalety chemicznej 200 zł</li>
          <li>Wydanie przez armatora toalety chemicznej 50zł</li>
          <li>Za czarter na jedną dobę cena wzrasta o 50%</li>
          <li>Za czarter na dwie doby cena wzrasta o 25%</li>
        </ul>
      </div>
    </div>
    <div className="additional-info">
      <p>
        Kaucja wynosi <strong>800</strong> zł.
      </p>
      <p>
        Jacht oraz silnik są ubezpieczone w zakresie: OC, JACHT CASCO, NW I
        rzeczy osobistych załogi.
      </p>
    </div>
  </div>
);
export default PricesPage;
