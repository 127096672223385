import React from "react";
import "./styles/css/homepage.css";
import mazury from "./resources/images/zdjecie_frontowe.jpg";
import deska_kafelek from "./resources/images/supy/sup_na_glowna.jpg";
import { useNavigate } from "react-router-dom";
import { navigateTo } from "./utils/navigation";
import { boats, nameToPath } from "./boats";
const HomePage = () => {
    const navigate = useNavigate();
    return (
        <div className="homepage-container">
            <div className="photo-container">
                <img src={mazury} alt="mazury z góry" />
                <div className="text">
                    <h1>Zakochaj się w Mazurach!</h1>
                    <p>
                        Red Jacht czarter oferuje profesjonalną usługę wynajmu
                        jachtu żaglowego i desek SUP. Gwarantujemy świetnie
                        przygotowany jacht, miłą obsługę oraz niezawodny serwis.
                        Z chęcią pomożemy ci w organizacji twojego rejsu marzeń.
                        Jeżeli nie posiadacie patentu żeglarza jachtowego lub po
                        prostu pragniecie podszkolić się i poczuć pewniej pod
                        żaglami, możecie skorzystać z usługi doświadczonego
                        lokalnego skippera, który bezstresowo wprowadzi was w
                        żeglarski świat oraz przygotuje przykładową trasę rejsu
                        bądź zarekomenduje najciekawsze mazurskie porty.
                    </p>
                </div>
            </div>
            <div className="tiles-container">
                {boats.map(boat =>    <div
                key ={boat.name}
                    className="tile"
                    onClick={() => navigateTo(nameToPath(boat.name), navigate)}
                >
                    <h2>{boat.name}</h2>
                    <img className="ludka" src={boat.mainImage} alt="jaaacht" />
                </div>)}
             
                <div
                    className="tile"
                    onClick={() => navigateTo("/deski-sup", navigate)}
                >
                    <h2>Wypożyczalnia desek SUP</h2>
                    <img src={deska_kafelek} alt="jaaacht" />
                </div>
            </div>
        </div>
    );
};

export default HomePage;
