import { Route, Routes } from "react-router-dom";
import "./App.css";
import { boats, nameToPath } from "./boats";
import Contact from "./Contact";
import DeskiSup from "./DeskiSup";
import Footer from "./Footer";
import HomePage from "./HomePage";
import { useWindowSize } from "./hooks/useWindowSize";
import Boat from "./Boat/Boat";
import Navbar from "./Navbar";
import PricesPage from "./PricesPage";
import "./styles/css/styles.css";

function App() {
    const scrollY = useWindowSize();
    return (
        <div className="App">
            <Navbar />
            <div className={"main" + (scrollY !== 0 ? " smaller" : "")}>
                <Routes>
                    {boats.map(boat => <Route key = {boat.name} path={nameToPath(boat.name)} element={<Boat boat={boat} />} />)}
                    <Route path="/deski-sup" element={<DeskiSup />} />
                    <Route path="/" element={<HomePage />} />
                    <Route path="/cennik" element={<PricesPage />} />
                    <Route path="/kontakt" element={<Contact />} />
                </Routes>
            </div>
            <Footer />
        </div>
    );
}

export default App;
