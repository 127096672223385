import React, { useLayoutEffect, useState } from "react";

export function useWindowSize() {
    const [scrollY, setScrollY] = useState(0);
    useLayoutEffect(() => {
        function updateSize() {
            setScrollY(window.scrollY);
        }
        window.addEventListener("scroll", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return scrollY;
}
